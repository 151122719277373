<script
    lang="ts"
    setup
>
    type Props = {
        bottom?: boolean
    }

    const props = withDefaults(defineProps<Props>(), {
        bottom: false
    })

    const style = useCssModule()

    const classes = computed<string[]>(() => [
        style['scroll-gradient'],
        props.bottom ? style['scroll-gradient--bottom'] : style['scroll-gradient--top']
    ])
</script>

<template>
    <div :class="classes"></div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .scroll-gradient
        pointer-events: none
        position: absolute
        width: 100%
        height: 50px
        left: 0

        &--top
            top: 0
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 100%)

        &--bottom
            bottom: 0
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 100%)
</style>
