<script
    lang="ts"
    setup
>
    type Props = {
        hideTop?: boolean
        hideBottom?: boolean
        observeMutation?: boolean
    }

    type Emit = {
        (event: 'mutated', el: HTMLElement): void
    }

    const props = withDefaults(defineProps<Props>(), {
        hideTop: false,
        hideBottom: false,
        observeMutation: false
    })

    const emit = defineEmits<Emit>()

    const slotRef = ref<HTMLElement>()

    const { arrivedTop, arrivedBottom, touch } = useScroll(slotRef)

    const showTop = computed<boolean>(() => {
        return !props.hideTop && !arrivedTop.value
    })

    const showBottom = computed<boolean>(() => {
        return !props.hideBottom && !arrivedBottom.value
    })

    const setSlotRef = (el?: HTMLElement): void => {
        slotRef.value = el
    }

    if (props.observeMutation) {
        observeElementMutation(
            slotRef,
            () => {
                if (!slotRef.value) {
                    return
                }

                setTimeout(touch, 0)

                emit('mutated', slotRef.value)
            },
            { childList: true }
        )
    }

    defineExpose({
        touchScroll: touch
    })
</script>

<template>
    <div class="relative">
        <Transition name="scroll-gradient-transition">
            <AppScrollGradient
                v-if="!props.hideTop"
                v-show="showTop"
                key="shadow-top"
            />
        </Transition>

        <slot :slot-ref="setSlotRef"/>

        <Transition name="scroll-gradient-transition">
            <AppScrollGradient
                v-if="!props.hideBottom"
                v-show="showBottom"
                key="shadow-bottom"
                bottom
            />
        </Transition>
    </div>
</template>

<style lang="sass">
    .scroll-gradient-transition
        &-enter-from,
        &-leave-to
            max-height: 0

        &-enter-active
            transition: max-height 220ms var(--transition-default-ease)

        &-leave-active
            transition: max-height 60ms var(--transition-default-ease)

        &-enter-to,
        &-leave-from
            max-height: 50px
</style>
